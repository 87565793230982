export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setOrders (state, data) {
    state.orders = data;
  },
  setOrder (state, data) {
    state.order = data;
  },
  setPagination (state, data) {
    state.pagination = data;
  },
  setSearchResult (state, data) {
    state.totalOrders = data.total_found;
    state.orders = data.result;
  }
};
