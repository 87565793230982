export default {
  async testBVGMessageDecode ({ rootState, commit }, bvgMessage) {
    commit('setLoading', true);
    await rootState.apiHandle
      .post('/common/bvg_parsing', { msg: bvgMessage })
      .then(response => {
        commit('setBVGMessageResponse', response.data);
      })
      .catch((e) => {
        commit('setBVGMessageResponse', e.data);
      })
      .then(commit('setLoading', false));
  }
};
