export default {
  async loadPharmacies ({ rootState, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get('/pharmacy/')
      .then(response => {
        commit('setPharmacies', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async loadPharmacy ({ rootState, commit }, pharmacyId) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get(`/pharmacy/single?id=${pharmacyId}`)
      .then(response => {
        commit('setPharmacy', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async postPharmacy ({ rootState, state, commit }) {
    commit('setLoading', true);
    return rootState.apiHandle
      .post('/pharmacy/', state.pharmacy)
      .then(response => {
        commit('setPharmacy', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async patchPharmacy ({ rootState, state, commit }) {
    commit('setLoading', true);
    return rootState.apiHandle
      .patch('/pharmacy/', state.pharmacy)
      .then(response => {
        commit('setPharmacy', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  }
};
