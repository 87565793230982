// https://vuex.vuejs.org/en/state.html

import * as apiParams from '../plugins/api';
import axios from 'axios';

export default {
  loading: false,
  loggedIn: false,
  apiUrl: apiParams.apiUrl,
  authorization: localStorage.getItem('authorization'),
  apiHandle: axios.create({
    baseURL: apiParams.apiUrl,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('authorization')}`
    }
  })
};
