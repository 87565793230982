export default {
  async loadOrders ({ rootState, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get('/order/')
      .then(response => {
        commit('setOrders', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async loadOrder ({ rootState, commit }, orderId) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get(`/order/single?id=${orderId}`)
      .then(response => {
        commit('setOrder', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async patchOrder ({ rootState, commit }, data) {
    commit('setLoading', true);
    await rootState.apiHandle
      .patch('/order/', data)
      .then(response => {
        commit('setOrder', response.data);
        commit('setLoading', false);
      })
      .catch()
      .then();
  },
  async patchOrderStatus ({ rootState, commit }, data) {
    commit('setLoading', true);
    await rootState.apiHandle
      .patch('/order/status', data)
      .then(response => {
        commit('setOrder', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async searchOrders ({ rootState, commit }, search) {
    commit('setLoading', true);
    await rootState.apiHandle.post('/order/search', search)
      .then(response => {
        commit('setSearchResult', response.data);
        commit('setLoading', false);
      });
  }
};
