<template>
  <v-app id="inspire">
    <drawer :drawer="drawer" />
    <v-main class="grey lighten-3">
      <v-container
        fluid
        class="pt-0"
      >
        <v-row
          align="start"
          justify-lg="center"
        >
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  props: {
    source: String
  },
  computed: {
    ...mapState([ 'authorization', 'user' ]),
    ...mapState('user', [ 'user' ])
  },
  data: () => ({
    drawer: true
  }),
  async mounted () {
    // check if we logged in before: we should have a JWT token
    if (!this.authorization) {
      // no, login required, redirect to the login page
      this.$router.push({ name: 'Login' });
    } else if (this.authorization && !this.user) {
      // we have an existing JWT token, check if it is valid by gathing
      // all common info (user info, department info, etc.) from the server
      // we know the JWT was valid if the server returns a user
      await this.getCommon();
      if (!this.user) {
        // still no user, JWT token was invalid, delete the stored JWT token
        // and redirect to the login page
        this.logout();
        this.$router.push({ name: 'Login' });
      }
    }
  },
  methods: {
    ...mapActions([ 'getCommon' ]),
    ...mapMutations([ 'logout' ])
  }
};
</script>
