export default {
  pharmacies: null,
  pharmacy: null,
  loading: false,
  pharmacyTemplate: {
    name: '',
    idf_number: undefined,
    address_street_name: '',
    address_street_number: '',
    address_city: '',
    address_zipcode: ''
  }
};
