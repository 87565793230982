export default {
  orders: null,
  order: null,
  loading: false,
  pagination: {
    page: 1,
    itemsPerPage: 20
  },
  totalOrders: 0,
  searchParams: {
    query: '',
    filter_status: null,
    filter_date: null
  }
};
