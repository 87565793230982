import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "default" */ '../views/Login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "default" */ '../views/Orders.vue'),
    meta: { title: 'Orders' }
  },
  {
    path: '/apotheken',
    name: 'Apotheken',
    component: () => import(/* webpackChunkName: "default" */ '../views/Apotheken.vue'),
    meta: { title: 'Apotheken' }
  },
  {
    path: '/apotheek/:id',
    name: 'Apotheek',
    component: () => import(/* webpackChunkName: "default" */ '../views/Apotheek.vue'),
    meta: { title: 'Apotheek' }
  },
  {
    path: '/producten',
    name: 'Producten',
    component: () => import(/* webpackChunkName: "default" */ '../views/Producten.vue'),
    meta: { title: 'Producten' }
  },
  {
    path: '/prijzen',
    name: 'Prijzen',
    component: () => import(/* webpackChunkName: "default" */ '../views/Prijzen.vue'),
    meta: { title: 'Prijzen' }
  },
  {
    path: '/bvg_test',
    name: 'BVG Message Decoding Test Page',
    component: () => import(/* webpackChunkName: "default" */ '../views/BVGTest.vue'),
    meta: { title: 'BVG Message Decoding Test Page' }
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import(/* webpackChunkName: "default" */ '../views/Order.vue'),
    meta: { title: 'Order' }
  },
  {
    path: '/medicine/:id',
    name: 'Geneesmiddel',
    component: () => import(/* webpackChunkName: "default" */ '../views/Geneesmiddel.vue'),
    meta: { title: 'Geneesmiddel' }
  },
  {
    path: '/gebruikers',
    name: 'Gebruikers',
    component: () => import(/* webpackChunkName: "default" */ '../views/Gebruikers.vue'),
    meta: { title: 'Gebruikers' }
  },
  {
    path: '/gebruiker/:id',
    name: 'Gebruiker',
    component: () => import(/* webpackChunkName: "default" */ '../views/Gebruiker.vue'),
    meta: { title: 'Gebruiker' }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;
