export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setUsers (state, data) {
    state.users = data;
  },
  setUser (state, data) {
    state.user = data;
  },
  setSelectedUser (state, data) {
    state.selectedUser = data;
  },
  loadUserFromTemplate (state) {
    state.selectedUser = Object.assign({}, state.userTemplate);
  }
};
