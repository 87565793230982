export default {
  user: null,
  selectedUser: null,
  users: [],
  loading: false,
  userTemplate: {
    username: '',
    email: '',
    is_admin: false,
    is_active: true
  }
};
