export default {
  medicines: null,
  medicine: null,
  loading: false,
  search: {
    query: '',
    skip: 0,
    limit: 100
  }
};
