export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setPharmacies (state, data) {
    state.pharmacies = data;
  },
  setPharmacy (state, data) {
    state.pharmacy = data;
  },
  loadPharmacyFromTemplate (state) {
    state.pharmacy = Object.assign({}, state.pharmacyTemplate);
  }
};
