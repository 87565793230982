<template>
  <v-col
    :sm="sm"
    :md="md"
    :lg="lg"
  >
    <div
      class="d-flex flex-column"
      :style="{
        'border': 'thin solid rgba(0, 0, 0, 0.12)',
        'border-radius': '4px',
        'height': '100%',
        'background-color': color
      }"
      outlined
    >
      <div class="flex-shrink-1 black--text pa-4 text-center">
        {{ title }}
      </div>
      <div class="d-flex flex-grow-1 black--text pa-4 text-center align-content-center justify-center align-center">
        <slot>
          Default Content
        </slot>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Default Title'
    },
    color: {
      type: String,
      default: '#FFF'
    },
    sm: {
      type: Number,
      default: 12
    },
    md: {
      type: Number,
      default: 6
    },
    lg: {
      type: Number,
      default: 4
    }
  }
};
</script>
