export default {
  async loadMedicines ({ rootState, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get('/medicine/')
      .then(response => {
        commit('setMedicines', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async searchMedicines ({ rootState, state, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get('/medicine/search', { params: { query: state.search.query, skip: state.search.skip, limit: state.search.limit } })
      .then(response => {
        commit('setMedicines', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async loadMedicine ({ rootState, commit }, medicineId) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get(`/medicine/single?id=${medicineId}`)
      .then(response => {
        commit('setMedicine', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  }
};
