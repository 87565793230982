export default {
  async getUsers ({ rootState, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get('/users/')
      .then(response => {
        commit('setUsers', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async getUser ({ rootState, commit }, userId) {
    commit('setLoading', true);
    await rootState.apiHandle
      .get(`/users/single?id=${userId}`)
      .then(response => {
        commit('setSelectedUser', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async postUser ({ rootState, state, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .post('/users/', state.selectedUser)
      .then(response => {
        commit('setSelectedUser', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  },
  async patchUser ({ rootState, state, commit }) {
    commit('setLoading', true);
    await rootState.apiHandle
      .patch('/users/', state.selectedUser)
      .then(response => {
        commit('setSelectedUser', response.data);
      })
      .catch()
      .then(commit('setLoading', false));
  }
};
