<template>
  <v-navigation-drawer
    :mini-variant="$vuetify.breakpoint.smAndDown"
    :expand-on-hover="$vuetify.breakpoint.smAndDown"
    mini-variant-width="100"
    app
    dark
    permanent
    stateless
    width="240"
    color="blue-grey darken-4"
  >
    <v-container
      class="white--text"
    >
      <v-row justify="center">
        <v-col
          class="text-center"
          cols="8"
        >
          <v-img
            contain
            eager
            src="/img/icons/medstone-icon-200x200.png"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="auto">
          <span :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">Medstone</span>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col sm="auto">
          <span :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1'">Apotheek Bestelportaal</span>
        </v-col>
      </v-row>
      <v-row
        v-if="user"
        justify="center"
      >
        <v-col sm="auto">
          <span :class="$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1'">{{ user.email }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-list dense>
            <v-list-item
              v-for="nav in navigation"
              :key="nav.name"
              link
              :to="nav.path"
            >
              <v-list-item-action>
                <v-icon>{{ nav.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ nav.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    drawer: Boolean
  },
  data () {
    return {
      mini: false
    };
  },
  computed: {
    ...mapState('user', [ 'user' ]),
    navigation () {
      if (this.user) {
        return [
          { name: 'Home', icon: 'mdi-home', path: '/' },
          { name: 'Orders', icon: 'mdi-file-document', path: '/orders' },
          { name: 'Apotheken', icon: 'mdi-home-city', path: '/apotheken' },
          { name: 'Producten', icon: 'mdi-pill', path: '/producten' },
          { name: 'Gebruikers', icon: 'mdi-account', path: '/gebruikers' },
          { name: 'BVG Decodeer test', icon: 'mdi-message-bulleted', path: '/bvg_test' }
        ];
      } else {
        return [
          { name: 'Login', icon: 'mdi-lock', path: '/login' },
          { name: 'BVG Decodeer test', icon: 'mdi-message-bulleted', path: '/bvg_test' }
        ];
      }
    }
  }
};
</script>

<style>

#drawer {
  background-color: #5f634f;
}

</style>
