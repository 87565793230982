export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setCommon (state, data) {
    state.orderCountPerStatus = data.order_count_per_status;
  },
  setBVGMessageResponse (state, data) {
    state.decodedMessage = data;
  }
};
