import axios from 'axios';

export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setLoggedIn (state, data) {
    state.loggedIn = data;
  },
  setCommon ({ state, commit }, data) {
    state.loggedIn = true;
    commit('user/setUser', data.user);
  },
  loginSuccess (state, data) {
    // set the authorization in the VueX store
    state.authorization = data.access_token;
    // save the authorization token to local storage
    localStorage.setItem('authorization', state.authorization);
    // create an Axios API handle that automatically adds the Authorization header
    state.apiHandle = axios.create({
      baseURL: state.apiUrl,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${data.token_type} ${state.authorization}`
      }
    });
  },
  logout (state) {
    // remove the authorization in the VueX state
    state.authorization = undefined;
    // delete the authorization from localStorage
    localStorage.removeItem('authorization');
    // remove the authorized Axios API handle
    state.apiHandle = axios.create({
      baseURL: state.apiUrl,
      withCredentials: false,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }
};
