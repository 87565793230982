export default {
  setLoading (state, data) {
    state.loading = data;
  },
  setMedicines (state, data) {
    state.medicines = data;
  },
  setMedicine (state, data) {
    state.medicine = data;
  }
};
