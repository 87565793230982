export const nl = {
  common: {
    name: 'Naam',
    headOfTreatment: 'Hoofdbehandelaar',
    reanimationPolicy: 'Reanimatiebeleid',
    location: 'Locatie',
    notifications: 'Meldingen',
    patientNumber: 'Patiëntnummer',
    dateOfBirth: 'Geboortedatum',
    residence: 'Woonplaats',
    street: 'Straat',
    houseNumber: 'Huisnummer',
    address: 'Adres',
    zipcode: 'Postcode',
    generalPractitioner: 'Huisarts',
    pharmacist: 'Apotheek',
    sex: 'Geslacht',
    bloodType: 'Bloedgroep',
    sexNames: {
      male: 'Man',
      female: 'Vrouw'
    }
  },
  tables: {
    patients: {
      headerText: 'Patiënten',
      searchText: 'Zoeken',
      noResults: 'Geen resultaten'
    }
  }
};
