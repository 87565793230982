<template>
  <v-card
    flat
    tile
  >
    <v-toolbar
      flat
      color="primary"
    >
      <slot name="toolbar">
        <v-btn
          v-if="back"
          icon
          class="white--text"
          @click="$router.go(-1)"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">
          {{ title ? title : $route.meta.title }}
        </v-toolbar-title>
      </slot>
    </v-toolbar>
    <v-progress-linear
      :style="{
        visibility: loading ? 'visible' : 'hidden'
      }"
      indeterminate
      color="grey"
      background-color="white"
    />
    <slot>
      Default content
    </slot>
  </v-card>
</template>

<script>
export default {
  props: {
    back: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>
