<template>
  <card-layout>
    <v-container fluid>
      <v-row v-if="orderCountPerStatus">
        <page-item
          title="Nieuwe orders"
          color="rgba(235, 67, 52, 0.1)"
        >
          {{ orderCountPerStatus.received }}
        </page-item>
        <page-item
          title="Ontvangen orders"
          color="rgba(245, 197, 66, 0.2)"
        >
          {{ orderCountPerStatus.processing }}
        </page-item>
        <page-item
          title="Orders in backorder"
          color="rgba(66, 117, 245, 0.2)"
        >
          {{ orderCountPerStatus.on_backorder }}
        </page-item>
        <page-item
          title="Verwerkte orders"
          color="rgba(90, 245, 66, 0.2)"
        >
          {{ orderCountPerStatus.processed }}
        </page-item>
        <page-item
          title="Geannuleerde orders"
          color="rgba(55, 71, 79, 0.2)"
        >
          {{ orderCountPerStatus.cancelled }}
        </page-item>
      </v-row>
    </v-container>
  </card-layout>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';
import HomePageItem from '@/components/cards/HomeItem';

export default {
  components: {
    'page-item': HomePageItem
  },
  computed: {
    ...mapState('common', [ 'orderCountPerStatus' ])
  }
};
</script>
