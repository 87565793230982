import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

// import { i18n } from '@/plugins/i18n'

import Drawer from '@/views/Drawer';
import CardLayout from '@/views/CardLayout';
import i18n from './i18n';

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

Vue.component('Drawer', Drawer);
Vue.component('CardLayout', CardLayout);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
