// https://vuex.vuejs.org/en/actions.html

export default {
  login ({ state, dispatch, commit }, data) {
    commit('setLoading', true);
    state.apiHandle.post('/auth/login', data)
      .then(response => {
        // login was successfull, set the autorization token
        commit('loginSuccess', response.data);
        // load the user we logged in with
        dispatch('getCommon');
      })
      .catch(() => {
        // login was NOT successfull
        commit('loginError', 'Invalid username or password');
        // report that we finished the request
        commit('setLoading', false);
      });
  },
  async getCommon ({ state, commit }) {
    commit('setLoading', true);
    await state.apiHandle
      .get('/common/')
      .then(response => {
        commit('user/setUser', response.data.user);
        commit('common/setCommon', response.data);
        commit('setLoggedIn', true);
      })
      .catch(() => {})
      .then(commit('setLoading', false));
  }
};
